import React from 'react';
import styled from '@emotion/styled';
import fonts from 'style/fonts';
import { text } from 'style/mixin';

type CaptionProps = {};

const Container = styled.p<CaptionProps>`
  opacity: 0.4;
  font-family: ${fonts.jaGothic};
  ${text(12, 24 / 12)}
  letter-spacing: 0.04em;
`;

export const Caption: React.FC<CaptionProps> = ({ ...props }) => {
  return <Container {...props}>{props.children}</Container>;
};
